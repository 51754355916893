<template>
<div class="My">
	
	<div class="Nav" :style="'width:' + ($store.getters.getIsLeftMenuCollapse ? '60px':'220px')">
		
		<div class="Title" @click="SwitchMenuCollapse(false)">
			
			<ul v-if="$store.getters.getIsLeftMenuCollapse == false">
				<h1>{{$store.getters.getAppName}} - 用户中心</h1>
				<li @click="$Jump('/')">返回首页</li>
			</ul>
		</div>
		
		<div class="Menus">
			<div style="width: 100%;height: 100%;">
				<vuescroll :ops="vuescroll_ops" ref="vs">
					<el-menu
					      default-active="1-1"
						  :collapse="$store.getters.getIsLeftMenuCollapse"
					      background-color="rgba(38,59,97,1)"
					      text-color="rgba(181,195,212,1)"
						  :collapse-transition="false"
					      active-text-color="rgba(40,149,251,1)" style="border-right-width: 0px;">
						  
						  <template v-if="$store.getters.getIdentity == 'seller'">
						  <div class="Tips" v-if="$store.getters.getIsLeftMenuCollapse == false">
						  	卖家中心
						  </div>
						  
						  <el-menu-item index="201" @click="$Jump('/my/goods/goods_classification_list')">
						    <i class="el-icon-copy-document"></i>
						    <span slot="title">商品分组</span>
						  </el-menu-item>
						  
						   <el-menu-item index="101" @click="$Jump('/my/goods/goods_list')">
						     <i class="el-icon-shopping-bag-2"></i>
						     <span slot="title">商品管理</span>
						   </el-menu-item>
						   
						   <el-menu-item index="1306" @click="$Jump('/my/express_fee_template/list')">
						     <i class="el-icon-truck"></i>
						     <span slot="title">运费模板</span>
						   </el-menu-item>

							<el-menu-item index="333" @click="$Jump('/my/shop/brand_list')">
						     <i class="el-icon-price-tag"></i>
						     <span slot="title">品牌管理</span>
						   </el-menu-item>
						   
						   <el-menu-item index="1052" @click="$Jump('/my/shop/order_list')">
						     <i class="el-icon-tickets"></i>
						     <span slot="title">店铺订单</span>
						   </el-menu-item>
						   
						   <el-menu-item index="102" @click="$Jump('/my/shop/coupon_list')">
						     <i class="el-icon-tickets"></i>
						     <span slot="title">店铺优惠券</span>
						   </el-menu-item>
						   
						   <el-menu-item index="105" @click="$Jump('/my/seller/appeal_list?Status=90')">
						     <i class="el-icon-service"></i>
						     <span slot="title">客户售后/申诉</span>
						   </el-menu-item>
						   
						   <el-menu-item index="104" @click="$Jump('/my/shop/shop_setting')">
						     <i class="el-icon-setting"></i>
						     <span slot="title">店铺设置</span>
						   </el-menu-item>
						   
						   </template>
						   
						   
						  <template v-else>
						 <div class="Tips" v-if="$store.getters.getIsLeftMenuCollapse == false">
							 买家中心
						 </div>

						  <el-menu-item index="55" @click="$Jump('/my/order/list')">
						    <i class="el-icon-bank-card"></i>
						    <span slot="title">我买到的宝贝</span>
						  </el-menu-item>
						  
						  <el-menu-item index="56" @click="$Jump('/my/order/appeal_list')">
						    <i class="el-icon-bank-card"></i>
						    <span slot="title">我的申诉</span>
						  </el-menu-item>
						  
						  <el-menu-item index="5" @click="$Jump('/my/favorite/goods_list')">
						    <i class="el-icon-star-off"></i>
						    <span slot="title">我的收藏</span>
						  </el-menu-item>

						<el-menu-item index="3155" @click="$Jump('/my/coupon/coupon_list')">
						    <i class="el-icon-money"></i>
						    <span slot="title">我的优惠券</span>
						  </el-menu-item>
						  
						 <el-menu-item index="130" @click="$Jump('/my/address/list')">
						   <i class="el-icon-position"></i>
						   <span slot="title">收货地址管理</span>
						 </el-menu-item>
						 
						 <el-menu-item index="182" @click="$Jump('/my/invoice/list')">
						   <i class="el-icon-document-checked"></i>
						   <span slot="title">发票管理</span>
						 </el-menu-item>
						  </template>
						 
						 <div class="Tips">
						 	综合
						 </div>
						 
						  <el-menu-item index="10" @click="$Jump('/my/info/fileds')">
						    <i class="el-icon-user"></i>
						    <span slot="title">个人资料</span>
						  </el-menu-item>
						  
						  <el-menu-item index="103" @click="$Jump('/my/finance/index')">
						    <i class="el-icon-coin"></i>
						    <span slot="title">财务管理</span>
						  </el-menu-item>
						  
					      <el-menu-item index="2" @click="$Jump('/my/safty/pas_account')">
					        <i class="el-icon-lock"></i>
					        <span slot="title">修改密码</span>
					      </el-menu-item>
						  
						  <el-menu-item index="411" @click="$Jump('/my/workorder/list')">
						    <i class="el-icon-attract"></i>
						    <span slot="title">工单服务</span>
						  </el-menu-item>
						  
					    </el-menu>
						
				</vuescroll>
			</div>
		</div>
		
	</div>
	
	<div class="Desk">
		
		<div class="Top">
			<div class="LeftMenuCollapse">
				<div class="Slider" v-if="$store.getters.getIsLeftMenuCollapse == false" @click="SwitchMenuCollapse(true)">
					<span  class="el-icon-s-fold"></span>
				</div>
				
				<div class="Slider" v-if="$store.getters.getIsLeftMenuCollapse == true" @click="SwitchMenuCollapse(false)">
					<span  class="el-icon-s-unfold" ></span>
					
				</div>
			</div>
			<div class="Position"><span @click="$Jump('/')" style="cursor: pointer;">商城首页</span>  /  管理中心</div>
			<div class="Avatar">
				<div class="Img">
					<img src="../../assets/img/common/avatar_default.png" />
				</div>
				<div class="Info">
					<li>
						<el-dropdown @command="handleCommand">
						  <span class="el-dropdown-link">
						    {{$store.getters.getUserName}}<i class="el-icon-arrow-down el-icon--right"></i>
						  </span>
						  <el-dropdown-menu slot="dropdown">
						    <el-dropdown-item command="logout" >退出</el-dropdown-item>
						  </el-dropdown-menu>
						</el-dropdown>
					</li>
					<li>身份:{{$store.getters.getIdentityName}}</li>
				</div>
			</div>
		</div>
		
		<div class="Center">
			<div style="width: 100%;height: 100%;">
				<vuescroll :ops="vuescroll_ops2" ref="vs2">
					<div style="width: 100%;">
						<div>
							<router-view/>
						</div>
						<div class="Footer">© 2018-2020 {{$store.getters.getAppName}} 版权所有 沪ICP备20011720号-2</div>
					</div>
				</vuescroll>
			</div>
		</div>
		
	</div>
	
</div>
</template>

<script>
	import vuescroll from 'vuescroll'
	import {Menu,Submenu,MenuItem,MenuItemGroup,Dropdown,DropdownMenu,DropdownItem} from 'element-ui'
	export default {
	  name: 'MyDispatch',
	  props: {
	  },
	  data() {
	      return {
			  vuescroll_ops: {
			  	vuescroll: {},
			  	scrollPanel: {},
			  	rail: {
			  	},
			  	bar: {
			  		onlyShowBarOnScroll:false,
			  		background: '#ffffff',
			  		opacity: 0.1,
			  	}	
			  },
			  vuescroll_ops2: {
			  	vuescroll: {},
			  	scrollPanel: {},
			  	rail: {
			  	},
			  	bar: {
			  		onlyShowBarOnScroll:false,
			  		background: '#000000',
			  		opacity: 0.2,
			  	}	
			  }
	      }
	  },
	  created() {
	  	if(this.$store.getters.getToken == ''){
			this.$Jump('/')
		}
	  },
	  components: {
		vuescroll,
		'el-menu':Menu,
		'el-submenu':Submenu,
		'el-menu-item':MenuItem,
		'el-menu-item-group':MenuItemGroup,
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem
	  },
	  methods:{
		  SwitchMenuCollapse(_v){
			  this.$store.dispatch('setIsLeftMenuCollapse',_v)
		  },
		  handleCommand(command) {
				  if(command == 'logout'){
					  this.Logout()
				  }
		  },
		  Logout(){
			  this.$store.dispatch('setToken','')
			  this.$store.dispatch('setUserName','')
			  this.$store.dispatch('setIdentity','')
			  this.$store.dispatch('setIdentityName','')
			  this.$store.dispatch('setShopId','')
			  this.$store.dispatch('setTroopId','')
			  this.$Jump('/')
		},
	  }
	}
</script>

<style scoped>
.My{
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: nowrap;
}
.My .Nav{
	width: 220px;
	height: 100%;
	background-color: rgba(38,59,97,1);
	display: flex;
	flex-direction:column;
}
.My .Nav .Title{
	display: flex;
	align-items: center;
	height: 60px;
	width: 100%;
	padding-bottom: 10px;
	margin-bottom: 10px;
}
.My .Nav .Title img{
	width: 35px;
	height: 35px;
	margin-left: 10px;
	opacity: 0.8;
}
.My .Nav .Title ul{
	flex: 1;
	margin-left: 10px;
}
.My .Nav .Title ul h1{
	font-size: 1.2rem;
	height: 20px;
	line-height: 20px;
	color: rgba(255,255,255,0.6);
}
.My .Nav .Title ul li{
	height: 14px;
	line-height: 14px;
	cursor: pointer;
	color: rgba(255,255,255,0.6);
}
.My .Nav .Title ul li:hover{
	color: rgba(255,255,255,1);
}
.My .Nav .Menus{
	flex: 1;
	width: 100%;
	overflow: hidden;
}
.My .Nav .Menus .Tips{
	line-height: 30px;
	font-size: 0.9rem;
	color: rgba(255,255,255,0.3);
	padding-left: 30px;
}

.My .Desk{
	flex: 1;
	display: flex;
	flex-direction: column;
}
.My .Desk .Top{
	padding: 10px 10px;
	display: flex;
	background-color: #FFFFFF;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.04);
}
.My .Desk .Top .LeftMenuCollapse{
}
.My .Desk .Top .Position{
	flex: 1;
	margin-left: 10px;
	margin-right: 10px;
}
.My .Desk .Top .Avatar{
	display: flex;
	align-items: center;
}
.My .Desk .Top .Avatar .Img{
	margin-right: 10px;
}
.My .Desk .Top .Avatar .Img img{
	width: 40px;
	height: 40px;
	border-radius: 20px;
}
.My .Desk .Top .Avatar .Info{
	flex: 1;
}
.My .Desk .Top .Avatar .Info li{
	font-size: 0.9rem;
	cursor: pointer;
	color: #999999;
}
.My .Desk .Center{
	flex: 1;
	width: 100%;
	overflow: hidden;
}
.Footer{
	padding: 20px;
	text-align: center;
	color: #999999;
	font-size: 0.9rem;
}

/deep/ .Menus .el-menu-item:hover {
   background-color: rgba(0, 0, 0, 0.1) !important;
}
/deep/ .Menus .el-menu-item.is-active {
   background-color: rgba(0, 0, 0, 0.1) !important;
}

</style>
